import { useAuthStore } from '~~/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore()
  const router = useRouter()

  if (authStore?.state?.isLoggedIn) {
    return router.push({
      path: '/',
      query: {
        from: to.path
      }
    })
  }
})
